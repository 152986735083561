const selectElement = function() {
    const i = Math.round(Math.random());
    const el = (i === 0) ? 'films' : 'music';
    document.getElementById(el).classList.add('is-active');
};

selectElement();
document.querySelector('body').addEventListener('mouseleave', function() {
    selectElement();
});
document.querySelector('body').addEventListener('mouseenter', function() {
    document.querySelectorAll('.is-active').forEach(el => el.classList.remove('is-active'));
});